import React, { useEffect } from "react";

// React
import { useContext, useState, createContext } from "react";
import toast from "react-hot-toast";

// Services
import { post, del, get } from "../services/ttAPICaller";

// Router
import { navigate } from "@reach/router";

// Shared Components
import CustomToast from "../components/shared/toast/CustomToast";

export const authContext = createContext("");

export function AuthProvider({ children }) {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [authed, setAuthed] = useState(true);
  const [loading, setIsLoading] = useState(true);

  //Check to see if a user exists in local storage
  function setUserFromLocalStorage() {
    const loggedInUser = JSON.parse(localStorage.getItem("user"));
    if (loggedInUser != null) {
      setAuthed(true);
    } else {
      setAuthed(false);
    }
    setUser(loggedInUser);
  }

  useEffect(() => {
    //Checking if user is in local storage
    setUserFromLocalStorage();
  }, []);

  async function login(email, password) {
    setIsLoading(true);
    return post(
      "auth/login",
      JSON.stringify({ email: email, password: password })
    )
      .then(async (response) => {
        const data = await response;
        if (data.two_factor_enabled === true) {
          setIsLoading(false);
          return data;
        } else if (data) {
          setUser(data);
          setIsLoading(false);
          setAuthed(true);
          localStorage.setItem("user", JSON.stringify(data));
          return data;
        } else {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
      })
      .catch((error) => {
        toast.custom(
          (t) => (
            <CustomToast
              toast={t}
              toastType={"failure"}
              title={"Error"}
              message={error.toString()}
            />
          ),
          { duration: 3000 }
        );
        console.error("Error returned from API!", error);
      });
  }

  async function logout() {
    return del("auth/logout")
      .then(async (response) => {
        localStorage.clear();
        setAuthed(false);
        navigate("/app/login");
        toast.custom(
          (t) => (
            <CustomToast
              toast={t}
              toastType={"success"}
              title={"Success!"}
              message={"You have successfully logged out."}
            />
          ),
          { duration: 3000 }
        );
      })
      .catch((error) => {
        toast.custom(
          (t) => (
            <CustomToast
              toast={t}
              toastType={"failure"}
              title={"Error"}
              message={"You have been logged out."}
            />
          ),
          { duration: 3000 }
        );
        localStorage.clear();
        setAuthed(false);
        navigate("/app/login");
        console.error("Error returned from API!", error);
      });
  }

  const checkAuthed = async () => {
    await get("two_factor_config")
      .then(async (response) => {
        const data = await response;
        setIsLoading(true);
        if (data === 200) {
          setAuthed(true);
          setIsLoading(false);
        } else {
          setAuthed(false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("error:" + error);
        toast.custom(
          (t) => (
            <CustomToast
              toast={t}
              toastType={"failure"}
              title={"Error"}
              message={error.toString()}
            />
          ),
          { duration: 3000 }
        );
        console.error("Error returned from API!", error);
      });
  };

  return {
    user,
    login,
    setAuthed,
    authed,
    loading,
    logout,
    checkAuthed,
    setUserFromLocalStorage,
  };
}
