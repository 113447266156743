import "./src/css/index.css";
import React from "react";
import { AuthProvider } from "./src/hooks/auth";
import { ConfirmContextProvider } from "./src/hooks/confirm";

export const wrapRootElement = ({ element }) => (
  <ConfirmContextProvider>
    <AuthProvider>{element}</AuthProvider>
  </ConfirmContextProvider>
);
