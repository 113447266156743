import React from "react";
// Shared Components
import CustomToast from "../components/shared/toast/CustomToast";
import toast from "react-hot-toast";

const baseURL = process.env.REACT_APP_BASE_URL;

export function get(functionURL) {
  return fetch(baseURL + functionURL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then(async (response) => {
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const data = isJson && (await response.json());

    // check for error response
    if (!response.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || response.status;
      toast.custom(
        (t) => (
          <CustomToast
            toast={t}
            toastType={"failure"}
            title={"Error"}
            message={error.toString()}
          />
        ),
        { duration: 3000 }
      );
      return error;
    }
    console.log("service data:" + JSON.stringify(data));
    if (data) {
      return data;
    } else {
      return response.status;
    }
  });
}

export function post(functionURL, postBody) {
  return fetch(baseURL + functionURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: postBody,
    credentials: "include",
  }).then(async (response) => {
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const data = isJson && (await response.json());

    // check for error response
    if (!response.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || response.status;
      toast.custom(
        (t) => (
          <CustomToast
            toast={t}
            toastType={"failure"}
            title={"Error"}
            message={error.toString()}
          />
        ),
        { duration: 3000 }
      );
      return Promise.reject(error);
    }
    console.log("service data:" + JSON.stringify(data));
    return data;
  });
}

export function put(functionURL, postBody) {
  return fetch(baseURL + functionURL, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: postBody,
    credentials: "include",
  }).then(async (response) => {
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const data = isJson && (await response.json());

    // check for error response
    if (!response.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || response.status;
      toast.custom(
        (t) => (
          <CustomToast
            toast={t}
            toastType={"failure"}
            title={"Error"}
            message={error.toString()}
          />
        ),
        { duration: 3000 }
      );
      return Promise.reject(error);
    }
    console.log("service data:" + JSON.stringify(data));
    return data;
  });
}

export function del(functionURL) {
  return fetch(baseURL + functionURL, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then(async (response) => {
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const data = isJson && (await response.json());

    // check for error response
    if (!response.ok) {
      // get error message from body or default to response status
      const error = (data && data.message) || response.status;
      return Promise.reject(error);
    }
    console.log("status:" + JSON.stringify(response.status));
    return response.status;
  });
}
