import * as React from "react"
import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import { BellIcon, CheckCircleIcon, EmojiSadIcon, EmojiHappyIcon } from "@heroicons/react/outline"

export default function CustomToast(props){
    const [border, setBorder] = useState(null)
    const [icon, setIcon] = useState(null)


    useEffect(() => {
        switch (props.toastType){
            case 'success':
                setBorder('border-greenTT')
                setIcon(<CheckCircleIcon className={'h-6 w-full text-greenTT'}/>)
                break
            case 'alert' :
                setBorder('border-yellowTT')
                setIcon(<BellIcon className={'h-6 w-full text-yellowTT'} />)
                break
            case 'failure' :
                setBorder('border-red')
                setIcon(<EmojiSadIcon className={'h-6 w-full text-red'} />)
                break
            default :
                console.log('got here')
                setBorder('border-black')
                setIcon(<EmojiHappyIcon className={'h-6 w-full'} />)
                break
        }
    }, [props.toastType])

    return(
        <div
            className={`${
                props.toast.visible ? 'animate-enter' : 'animate-leave'
            } max-w-sm w-full pointer-events-auto flex`}
        >
            <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                <div
                    className={`max-w-sm w-full bg-white shadow-lg rounded-lg border-2 ${border} pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden`}>
                    <div className="p-4">
                        <div className="flex items-start">
                            <div className="flex-shrink-0">
                                {icon}
                            </div>
                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                <p className="text-sm font-medium text-gray-900">
                                    {props.title}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                    {props.message}
                                </p>
                            </div>
                            <div className="ml-4 flex-shrink-0 flex">
                                <button
                                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        toast.remove(props.toast.id)
                                    }}
                                >
                                    <span className="sr-only">Close</span>
                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                         fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd"
                                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                              clipRule="evenodd"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}